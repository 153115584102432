import {TaxiController} from "./taxi_controller";
import {Loader} from "@googlemaps/js-api-loader";
import locationIcon from '../images/location.svg';

export default class extends TaxiController {
  static targets = [
    'map',
    'orders_main',
    'pickup_input',
    'drop_off_input',
    'orders_pickup',
    'search_pickup_address',
    'pickup_addresses_list',
    'orders_drop_off',
    'search_drop_off_address',
    'drop_off_addresses_list',
    'distance',
    'duration',
    'total_price',
    "selected_tariff_name",
    "tariff_link",
    "seat_price",
    "per_km_price"
  ];

  async initialize() {
    // init google maps constants map, geocoder
    this.map = null;
    this.geocoder = null;
    this.pick_up_marker = null;
    this.drop_off_marker = null;
    this.currentLocation = null;
    this.pickupLocation = null;
    this.pickupLocationName = null;
    this.dropoffLocation = null;
    this.dropoffLocationName = null;
    this.directionsService = null;
    this.directionsRenderer = null;
    this.direction_distance = null;
    this.direction_duration = null;
    this.taxi_total_price = null;
    this.tariff_per_km_price_cent = this.data.get('per-km-price-cents');
    this.tariff_seat_price_cent = this.data.get('seat-price-cents');
    this.tariff_name = this.data.get('tariff-name');
    this.tariff_id = this.data.get('tariff-id');

    this._changeTariff(this.tariff_name, this.tariff_seat_price_cent, this.tariff_per_km_price_cent);


    await this._initGoogleMap().then(() => {
      this.setMapHeight();
      window.addEventListener('resize', this.setMapHeight.bind(this));
    });
  }

  // async connect() {
  //
  // }

  select_tariff(event) {
    event.preventDefault();
    // console.log(event);
    let currentTarget = event.currentTarget;
    // console.log(currentTarget);
    let tariff_name = currentTarget.getAttribute('data-tariff-name');
    let seat_price_cents = currentTarget.getAttribute('data-seat-price-cents');
    let per_km_price_cents = currentTarget.getAttribute('data-per-km-price-cents');
    let tariff_id = currentTarget.getAttribute('data-tariff-id');
    this.tariff_name = tariff_name;
    this.tariff_seat_price_cent = seat_price_cents;
    this.tariff_per_km_price_cent = per_km_price_cents;
    this.tariff_id = tariff_id;
    this._changeTariff(tariff_name, seat_price_cents, per_km_price_cents);
  }

  _changeTariff(tariff_name, seat_price_cents, per_km_price_cents) {
    this.selected_tariff_nameTarget.innerHTML = tariff_name;
    this.seat_priceTarget.innerHTML = new Intl.NumberFormat('uz-UZ', {
      style: 'decimal',
      minimumFractionDigits: 0,
      useGrouping: true,
    }).format(seat_price_cents / 100).replace(/,/g, ' ') + " so'm";
    this.per_km_priceTarget.innerHTML = new Intl.NumberFormat('uz-UZ', {
      style: 'decimal',
      minimumFractionDigits: 0,
      useGrouping: true,
    }).format(per_km_price_cents / 100).replace(/,/g, ' ') + " so'm";
    this.calculateAndDisplayRoute().then(() => {
      console.log('Route calculated');
    });
  }

  continue_drop_off(event) {
    event.preventDefault();
    this.back_to_main(event);
    this.calculateAndDisplayRoute().then(() => {
      console.log('Route calculated');
    });
  }

  // search drop off address by user input
  async search_drop_off_address(event) {
    const query = this.search_drop_off_addressTarget.value;
    if (query.length < 3) {
      return;
    }

    const results = await this._searchAddress(query);
    this.displayDropOffAddresses(results);
  }

  displayDropOffAddresses(results) {
    const list = this.drop_off_addresses_listTarget;
    list.innerHTML = '';

    results.forEach(result => {
      const item = document.createElement('div');
      item.classList.add('map-ongoing', 'current-redirect');

      item.innerHTML = `
      <div class="map-icon">
        <img src="${locationIcon}" alt="location">
      </div>
      <div class="location-content">
        <h2>${result.formatted_address}</h2>
        <div class="boder-location"></div>
      </div>
    `;

      item.addEventListener('click', () => {
        this.search_drop_off_addressTarget.value = result.formatted_address;
        let location = {
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng()
        }
        this._mapCenter(location);

        this._dropOffMarkerPosition(location).then(
          // this._updateDropOffAddress(location)
        );
        list.innerHTML = ''; // Clear the list
      });

      list.appendChild(item);
    });
  }

  async _dropOffMarkerPosition(pos) {
    // console.log('Drop off position: ' + pos)
    this.dropoffLocation = pos;
    this.drop_off_marker.position = pos;
    await this._updateDropOffAddress(pos);
  }

  async _updateDropOffAddress(pos) {
    this.geocoder.geocode({ location: pos }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          this.search_drop_off_addressTarget.value = results[0].formatted_address;
          this.drop_off_inputTarget.value = results[0].formatted_address;
          this.dropoffLocationName = results[0].formatted_address;
        } else {
          window.alert("No results found");
        }
      } else {
        window.alert("Geocoder failed due to: " + status);
      }
    });
  }

  async set_drop_off_location(event) {
    event.preventDefault();
    this.dropoffLocation = await this.initGeolocation();
    this._mapCenter(this.dropoffLocation);
    this._dropOffMarkerPosition(this.dropoffLocation);
    await this._updateDropOffAddress(this.dropoffLocation);
  }

  pickup_continue(event) {
    this.show_drop_off(event);
  }

  continue(event) {
    event.preventDefault();
    // validate order params

    if (this._validateOrderParams(event)) {
      // create order send request to server
      this._createOrder().then(() => {
        // console.log('Order created');
      });

      // if order created successfully then redirect to payment page with post request
    }
  }

  async _createOrder() {
    // send request to server
    // return hash to redirect to payment page post request
    // send port request via Rails UJS
    const url = "/app/orders/"
    const formData = this._makeOrderData();
    // console.log(formData)

    Rails.ajax({
      url: url,
      type: 'post',
      data: formData,
      contentType: 'application/json',
      dataType: 'json',
      success: (data) => {
        if (data) {
          // console.log(data);
          this._buildPaymentFormAndSubmit(data);
        }
      },
      error: (data) => {
        // console.log(data)
      }
    })
  }

  // ПРИМЕР — Обмен с ПЦ в рамках выполнения Запроса webapi/Purchase
  // ИС ТСП перенаправляет Плательщика методом HTTP GET или POST на Платёжную форму ПЦ с использованием Запроса webapi/Purchase для ввода карточных данных:
  //   POST /webapi/Purchase HTTP/1.1
  // Host: test.payworld.online
  // Accept: */*
  // Content-Length: 72
  // Content-Type: application/x-www-form-urlencoded
  // signature=NTVjMDVlYmFlM2E1YmY1NmIxNTA3MzA2ZjZiNTU5NTQ=&sector=1&id=70365
  //
  // data
  // {
  //   order: {
  //    payment_data: {
  //      signature: 'NTVjMDVlYmFlM2E1YmY1NmIxNTA3MzA2ZjZiNTU5NTQ=',
  //      sector: '1',
  //      id: '70365',
  //      payer_id: '12345',
  //      url: 'https://test.payworld.online/webapi/Purchase'
  //    }
  //  }
  _buildPaymentFormAndSubmit(data) {
    let formData = data.order.payment_data;
    const form = document.createElement('form');
    // const form = document.createElement('form');
    form.method = 'POST';
    form.action = formData.url;

    const params = {
      signature: formData.signature,
      sector: formData.sector,
      id: formData.id,
      payer_id: formData.payer_id,
    };

    for (let [key, value] of Object.entries(params)) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = value;
      form.appendChild(input);
    }

    const paymentFormDiv = document.getElementById('payment_form');
    // console.log(form)
    //
    paymentFormDiv.appendChild(form);
    form.submit();
  }

  _makeOrderData() {
    const orderParams = {
      from_lng: this.pickupLocation.lng,
      from_lat: this.pickupLocation.lat,
      to_lng: this.dropoffLocation.lng,
      to_lat: this.dropoffLocation.lat,
      from_location_name: this.pickupLocationName,
      to_location_name: this.dropoffLocationName,
      distance_km: this.direction_distance,
      total_price: this.taxi_total_price,
      tariff_id: this.tariff_id
    };
    const params = {
      order_params: orderParams,
      action: 'create_order'
    }

    return new URLSearchParams(this._recursiveEntries(params)).toString();
  }

  _recursiveEntries(obj, parentKey = '') {
    let entries = [];
    for (let [key, value] of Object.entries(obj)) {
      let newKey = parentKey ? `${parentKey}[${key}]` : key;
      if (typeof value === 'object' && value !== null) {
        entries.push(...this._recursiveEntries(value, newKey));
      } else {
        entries.push([newKey, value]);
      }
    }
    return entries;
  }

  _validateOrderParams(event) {
    // validate pickup address
    if (!this.pickupLocation || !this.pickupLocationName) {
      // show pickup
      this.show_pickup(event);
      return false;
    }
    // validate drop off address
    if (!this.dropoffLocation || !this.dropoffLocationName) {
      // show drop off
      this.show_drop_off(event);
      return false;
    }
    // validate distance
    if (!this.direction_distance || !this.taxi_total_price) {
      // calculate distance
      this.calculateAndDisplayRoute().then(() => {
        // console.log('Route calculated');
      });
      return true;
    }
    return true;
  }

  show_drop_off(event) {
    event.preventDefault();
    this.hideOrdersMain();
    this.hideOrdersPickup();
    this.showDropOff();
    this.setMapHeight();
  }

  show_pickup(event) {
    event.preventDefault();
    // this.orders_mainTarget.classList.add('hidden');
    this.hideOrdersMain();
    this.hideDropOff();
    this.showOrdersPickup();
    this.setMapHeight();
  }

  back_to_main(event) {
    event.preventDefault();
    // this.orders_pickupTarget.classList.add('hidden');
    this.hideOrdersPickup();
    this.hideDropOff();
    this.showOrdersMain();
    this.setMapHeight();
  }

  clear_pickup_address() {
    this.search_pickup_addressTarget.value = '';
    this.search_pickup_addressTarget.focus();
  }

  clear_drop_off_address() {
    this.search_drop_off_addressTarget.value = '';
    this.search_drop_off_addressTarget.focus();
  }

  // search pickup address by user input
  // search after 500ms or when user press enter or input value length > 3
  async search_pickup_address(event) {
    // console.log(event)
    const query = this.search_pickup_addressTarget.value;
    // console.log(query)
    if (query.length < 3) {
      return;
    }

    const results = await this._searchAddress(query);
    this.displayAddresses(results);
  }

  async set_current_location(event) {
    event.preventDefault();
    this.currentLocation = await this.initGeolocation();
    this._mapCenter(this.currentLocation);
    this._pickUpMarkerPosition(this.currentLocation);
    await this._updatePickupAddress(this.currentLocation);
  }

  async calculateAndDisplayRoute() {
    if (!this.pickupLocation || !this.dropoffLocation) {
      return;
    }

    this.directionsRenderer.setMap(this.map);

    this.directionsService.route(
      {
        origin: this.pickupLocation,
        destination: this.dropoffLocation,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === "OK") {
          this.directionsRenderer.setDirections(response);
          const route = response.routes[0].legs[0];

          // round values
          // duration in minutes and distance in km
          // distance rounded to 1 decimal places
          // duration rounded to 0 decimal places
          this.direction_distance = route.distance.value / 1000;
          // this.direction_duration = route.duration.value / 60;
          // this.direction_duration = Math.round(this.direction_duration);
          this.direction_distance = Math.round(this.direction_distance * 10) / 10;
          // direction_distance round to up to 1, example 0.4 = 1, 0,6 = 1
          this.direction_distance = Math.ceil(this.direction_distance);
          // console.log(`direction_distance: ${this.direction_distance}`);
          // console.log(`tariff_seat_price_cent: ${this.tariff_seat_price_cent}`);
          // console.log(`tariff_per_km_price_cent: ${this.tariff_per_km_price_cent}`);
          this.taxi_total_price = parseInt(this.tariff_seat_price_cent) + (parseInt(this.tariff_per_km_price_cent) * this.direction_distance);

          // cents convert to so'm, 1 so'm = 100 cents, and round to up to 1000, 0 decimal places, format money, add currency 'so'm'
          this.taxi_total_price = Math.round(this.taxi_total_price / 100) * 100;
          // taxi_total_price round to up to 1, example 0.4 = 1, 0,6 = 1
          this.taxi_total_price = Math.ceil(this.taxi_total_price / 100);

          // console.log(`taxi_total_price: ${this.taxi_total_price}`);

          // format money no digits
          this.total_priceTarget.innerHTML = new Intl.NumberFormat('uz-UZ', {
            style: 'decimal',
            minimumFractionDigits: 0,
            useGrouping: true,
            // Customizing the grouping separator to space for 'uz-UZ' locale might not directly be supported.
            // As a workaround, format the number first, then replace commas with spaces.
          }).format(this.taxi_total_price).replace(/,/g, ' ') + " so'm";
          // console.log(this.taxi_total_price)
          this.distanceTarget.innerHTML = route.distance.text;
          // this.durationTarget.innerHTML = this.direction_duration + ' минут';

          // console.log(`Distance: ${route.distance.value}`);
          // console.log(`Duration: ${route.duration.value}`);
        } else {
          window.alert("Directions request failed due to " + status);
        }
      }
    );
  }

  async _initGoogleMap() {
    this.currentLocation = await this.initGeolocation();
    const loader = new Loader({
      apiKey: "AIzaSyBCm4dhZtr1toFtUraoMx3g_Rx0p8bxhpM",
      version: "weekly",
      language: "en",
      region: "UZ",
      libraries: ["places", "geometry"]
    });

    loader.load().then(async () => {
      const {Map} = await google.maps.importLibrary("maps");
      const {AdvancedMarkerElement} = await google.maps.importLibrary("marker");

      this.directionsService = new google.maps.DirectionsService();
      this.directionsRenderer = new google.maps.DirectionsRenderer({suppressMarkers: true});

      this.geocoder = new google.maps.Geocoder();
      this.map = this._initMap(Map);

      const marker_svg = this._pickUpMarkerSvg();
      this.pick_up_marker = new AdvancedMarkerElement({
        map: this.map,
        position: {lat: 41.27, lng: 69.22},
        content: marker_svg
      });

      const drop_off_marker_svg = this._dropOffUpMarkerSvg();
      this.drop_off_marker = new AdvancedMarkerElement({
        map: null,
        position: this.dropoffLocation || this.currentLocation || {lat: 41.27, lng: 69.22},
        content: drop_off_marker_svg
      });

      this._mapCenter(this.currentLocation);
      this.pickupLocation = this.currentLocation;
      this._pickUpMarkerPosition(this.currentLocation);
      await this._updatePickupAddress(this.currentLocation);

      this.map.addListener('drag', () => {
        const center = this.map.getCenter();
        const pos = {
          lat: center.lat(),
          lng: center.lng()
        };
        if (!this.orders_drop_offTarget.classList.contains('hidden')) {
          this._dropOffMarkerPosition(pos);
        } else {
          this._pickUpMarkerPosition(pos);
        }
        // this._pickUpMarkerPosition(pos);
      });
      // Добавляем обработчик события dragend
      this.map.addListener('dragend', async () => {
        const center = this.map.getCenter();
        const pos = {
          lat: center.lat(),
          lng: center.lng()
        };

        if (!this.orders_drop_offTarget.classList.contains('hidden')) {
          this._dropOffMarkerPosition(pos);
          await this._updateDropOffAddress(pos);
        } else {
          this._pickUpMarkerPosition(pos);
          await this._updatePickupAddress(pos);
        }
        // this._pickUpMarkerPosition(pos);
        // await this._updatePickupAddress(pos);
      });
    });
  }

  _pickUpMarkerSvg() {
    const markerSvgString = `
      <svg fill="#d00f09" height="48" width="48" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
      \t viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve">
      <path d="M256,0C149.3,0,64,85.3,64,192c0,36.9,11,65.4,30.1,94.3l141.7,215v0c4.3,6.5,11.7,10.7,20.2,10.7c8.5,0,16-4.3,20.2-10.7
      \tl141.7-215C437,257.4,448,228.9,448,192C448,85.3,362.7,0,256,0z M256,298.6c-58.9,0-106.7-47.8-106.7-106.8
      \tc0-59,47.8-106.8,106.7-106.8c58.9,0,106.7,47.8,106.7,106.8C362.7,250.8,314.9,298.6,256,298.6z"/>
      </svg>
    `;
    const parser = new DOMParser();

    return parser.parseFromString(markerSvgString, 'image/svg+xml').documentElement;
  }

  _dropOffUpMarkerSvg() {
    const markerSvgString = `
      <svg fill="#00a266" height="48" width="48" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
      \t viewBox="0 0 512 512" enable-background="new 0 0 512 512" xml:space="preserve">
      <path d="M256,0C149.3,0,64,85.3,64,192c0,36.9,11,65.4,30.1,94.3l141.7,215v0c4.3,6.5,11.7,10.7,20.2,10.7c8.5,0,16-4.3,20.2-10.7
      \tl141.7-215C437,257.4,448,228.9,448,192C448,85.3,362.7,0,256,0z M256,298.6c-58.9,0-106.7-47.8-106.7-106.8
      \tc0-59,47.8-106.8,106.7-106.8c58.9,0,106.7,47.8,106.7,106.8C362.7,250.8,314.9,298.6,256,298.6z"/>
      </svg>
    `;
    const parser = new DOMParser();

    return parser.parseFromString(markerSvgString, 'image/svg+xml').documentElement;
  }

  _mapCenter(pos) {
    this.map.setCenter(pos);
  }

  _pickUpMarkerPosition(pos) {
    console.log(pos)
    this.pickupLocation = pos;
    this.pick_up_marker.position = pos;
  }

  async _searchAddress(query) {
    // console.log(query)
    // region UZ
    // territory Tashkent, and Tashkent region
    let query_with_region = `${query}, Tashkent, UZ`;
    return new Promise((resolve, reject) => {
      this.geocoder.geocode({address: query_with_region}, (results, status) => {
        if (status === "OK") {
          resolve(results);
        } else {
          reject(new Error('Geocode was not successful for the following reason: ' + status));
        }
      });
    });
  }

  displayAddresses(results) {
    const list = this.pickup_addresses_listTarget;
    list.innerHTML = '';

    results.forEach(result => {
      const item = document.createElement('div');
      item.classList.add('map-ongoing', 'current-redirect');

      item.innerHTML = `
        <div class="map-icon">
          <img src="${locationIcon}" alt="location">
        </div>
        <div class="location-content">
          <h2>${result.formatted_address}</h2>
          <div class="boder-location"></div>
        </div>
      `;

      item.addEventListener('click', () => {
        this.search_pickup_addressTarget.value = result.formatted_address;
        let location = {
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng()
        }
        // console.log(location)
        this._mapCenter(location);
        this._pickUpMarkerPosition(location);
        this._updatePickupAddress(location).then(
          // this._updatePickupAddress(location)
        );
        list.innerHTML = ''; // Clear the list
      });

      list.appendChild(item);
    });
  }

  async _updatePickupAddress(pos) {
    this.geocoder.geocode({ location: pos }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          this.search_pickup_addressTarget.value = results[0].formatted_address;
          this.pickup_inputTarget.value = results[0].formatted_address;
          this.pickupLocationName = results[0].formatted_address;
        } else {
          window.alert("No results found");
        }
      } else {
        window.alert("Geocoder failed due to: " + status);
      }
    });
  }


  setMapHeight() {
    let homescreenWrapElement;
    if (!this.orders_mainTarget.classList.contains('hidden')) {
      homescreenWrapElement = document.querySelector('#order-new-screen');
    } else if (!this.orders_pickupTarget.classList.contains('hidden')) {
      homescreenWrapElement = document.querySelector('#order-pickup-screen');
    } else if (!this.orders_drop_offTarget.classList.contains('hidden')) {
      homescreenWrapElement = document.querySelector('#order-drop-off-screen');
    }
    const viewportHeight = window.innerHeight;
    const homescreenWrapHeight = homescreenWrapElement.offsetHeight;
    const mapHeight = viewportHeight - homescreenWrapHeight;
    this.mapTarget.style.height = `${mapHeight}px`;
  }

  _initMap(Map){
    return new Map(this.mapTarget, {
      center: {lat: 41.27, lng: 69.22},
      zoom: 18,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: false,
      mapId: 'e85747fe87702c00'
    });
  }

  // private
  showOrdersMain() {
    this.orders_mainTarget.classList.remove('hidden');
    this.map.setOptions({draggable: false});
  }

  showOrdersPickup() {
    this.orders_pickupTarget.classList.remove('hidden');
  }

  hideOrdersMain() {
    this.orders_mainTarget.classList.add('hidden');
    this.map.setOptions({draggable: true});
  }

  hideOrdersPickup() {
    this.orders_pickupTarget.classList.add('hidden');
  }

  showDropOff() {
    this.orders_drop_offTarget.classList.remove('hidden');
    // show drop off marker
    this.drop_off_marker.setMap(this.map);
  }

  hideDropOff() {
    this.orders_drop_offTarget.classList.add('hidden');
  }

  // return position
  initGeolocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        }, () => {
          this.handleLocationError(true);
          reject(new Error('The Geolocation service failed.'));
        });
      } else {
        this.handleLocationError(false);
        reject(new Error('Your browser doesn\'t support geolocation.'));
      }
    });
  }

  handleLocationError(browserHasGeolocation, pos) {
    console.log(browserHasGeolocation ?
      'Error: The Geolocation service failed.' :
      'Error: Your browser doesn\'t support geolocation.');
  }
}
